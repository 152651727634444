
import DateFormatMixin from "@/mixins/DateFormatMixin.vue";
import { EventBus } from "@/plugins/eventBus";
import axios, { AxiosError } from "axios";
import Vue, { VueConstructor } from "vue"

type NotificationData = {
  [key: string]: number|string
}

interface Notification {
  id: number,
  type: number,
  read: boolean,
  created_at: string,
  title: string,
  message: string,
  data: NotificationData,
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof DateFormatMixin>>).extend({
  name: "Notification",
  mixins: [DateFormatMixin],
  props: {
    notification: {
      type: Object as () => Notification,
    }
  },
  methods: {
    readNotification() {
      axios.put(`/v1/notifications/${this.notification.id}`,
        {
          read: !this.notification.read
        }
      )
      .then(() => { // HTTP status between 200 & 299
        this.notification.read = !this.notification.read // reflect success on front data
      }).catch((error: AxiosError) => {
        EventBus.$emit('snackbar', { axiosError: error })
      })
    },
  }
})
